import colors, { ColorStyles } from 'shared/utils/colors';

export interface Theme {
  borders: string[];
  breakpoints: string[];
  colors: ColorStyles;
  fonts: {
    normal: string;
  };
  fontSizes: string[];
  fontWeights: {
    light: number;
    book: number;
    medium: number;
    semibold: number;
    bold: number;
  };
  space: number[];
  textStyles: {
    caps: {
      textTransform: string;
      letterSpacing: string;
    };
  };
  radii: string[];
}

const breakpoints = ['544px', '768px', '1012px', '1280px'];

const fontSizes = [
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '28px',
  '32px',
  '36px',
  '42px',
  '48px',
  '60px',
  '72px',
  '96px',
];

const fontWeights = {
  light: 200,
  book: 400,
  medium: 600,
  semibold: 700,
  bold: 800,
} as const;

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256];

const textStyles = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
};

const radii = ['0', '4px', '8px', '16px', '100px'];

const borders = ['0', '1px solid', '2px solid'];

const theme: Theme = {
  borders,
  breakpoints,
  colors,
  fonts: {
    normal: fontStack([
      'VSCO Gothic',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ]),
  },
  fontSizes,
  fontWeights,
  space,
  textStyles,
  radii,
};

function fontStack(fonts: string[]) {
  return fonts.map(font => (font.includes(' ') ? `"${font}"` : font)).join(', ');
}

export default theme;
