export enum Theme {
  LIGHT = 1,
  DARK,
}
export interface ColorStyles {
  black: string;
  charcoalDark: string;
  charcoal: string;
  charcoalLight: string;
  grayDark: string;
  gray: string;
  grayLight: string;
  eggWhite: string;
  offWhite: string;
  white: string;
  dryConcrete: string;
  toupe: string;
  wetConcrete: string;
  cream: string;
  lightGold: string;
  gold: string;
  teal: string;
  blue: string;
  darkBlue: string;
  midnightBlue: string;
  royalBlue: string;
  pink: string;
  darkPink: string;
  orange: string;
  red: string;
  green: string;
  gray05: string;
  gray15: string;
  gray25: string;
  gray35: string;
  gray55: string;
  gray75: string;
  gray85: string;
  gray95: string;
  saffronL50: string;
  saffronD50: string;
  yellow: string;
  redL: string;
  redR: string;
  blueL: string;
  blueR: string;
}

export const colors = {
  // Black -> White
  black: '#000',
  charcoalDark: '#222',
  charcoal: '#444',
  charcoalLight: '#666',
  grayDark: '#888',
  gray: '#aaa',
  grayLight: '#ddd',
  eggWhite: '#eee', // also known as whiteSmokeDark in some old design docs
  offWhite: '#f6f6f6', // also known as whiteSmoke in some old design docs
  white: '#fff',

  // Beige -> Gold
  dryConcrete: '#e8e9e3',
  toupe: '#c6c7bf',
  wetConcrete: '#8c8d87',
  cream: '#f7f3dd',
  lightGold: '#c3b382',
  gold: '#ae995a',

  // Cold -> Warm
  teal: '#a2cad3',
  blue: '#0000ff',
  darkBlue: '#0f3ba9',
  midnightBlue: '#355070',
  royalBlue: '#1d71d8',
  pink: '#ffaebc',
  darkPink: '#c96857',
  orange: '#ff5f04',
  red: '#ff3401',

  // Other
  green: '#01a200',

  // New color styles from VSCO design system 2.0 (WIP)
  // Refer to Figma for more details

  // grayscale
  gray05: '#F6F6F6',
  gray15: '#DDDDDD',
  gray25: '#C0C0C0',
  gray35: '#AAAAAA',
  gray55: '#737373',
  gray75: '#444444',
  gray85: '#222222',
  gray95: '#111111',

  // color
  saffronL50: '#F1A900',
  saffronD50: '#FFC53C',
  yellow: '#FFF000',
  redL: '#F73100',
  redR: '#FF4928',
  blueL: '#0000FF',
  blueR: '#1347FF',
} as const;

export default colors;
