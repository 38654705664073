module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-remark-images@3.3.27_idlvaebcio2kpr4j6sy2bmlel4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":675},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-analytics@2.3.13_kvhd34a55f7vqb4kj4jpddzlfm/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-23285656-1"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@2.7.0_gatsby@2.24.51/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KDXD8TQ","includeInDevelopment":false,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@2.4.25_gatsby@2.24.51/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VSCO’s Engineering Blog","short_name":"vsco-eng-blog","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"content/assets/vsco-seal.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b52353266b906c31c23b36aeb2b621b2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
